<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home mt-3 border-bottom-red">
        <div class="container-fluid m-0 p-0">
            <div class="row m-0 p-0">
                <div class="row">
                    <div class="col-2">
                        <div class="content-block">
                            <img class="icon-red img-left" src="../../assets/images/hubungi-kami-w.svg" alt="icon">
                        </div>
                    </div>

                    <div class="col-10">
                        <div class="row border-bottom-grey">
                            <div class="col-lg-6">

                                <div class="content-block">
                                    <h3>Contact <span class="alternate">Us</span></h3>

                                    <div class="hubungi">
                                        <div class="hubungi-title"><h3>Headquarters</h3></div>
                                        <h6 class="hubungi-pt">PT Pelayaran Bahtera Adhiguna</h6>
                                            
                                        <ul>
                                            <li class="list-one">
                                                <span>St. East Kalibesar No.10-12 West Jakarta 11110 - Indonesia</span>
                                            </li>

                                            <li class="list-two">
                                                <span>+62 216912547, 216912548, 216912549</span>
                                            </li>

                                            <li class="list-three">
                                                <span>+62-21 216901450, 6902726</span>
                                            </li>

                                            <li class="list-four">
                                                <span>pelba@bahteradhiguna.co.id</span>
                                            </li>

                                            <li class="list-five">
                                                <span>www.bahteradhiguna.co.id</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-6">
                                <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63471.408714902325!2d106.77703537910153!3d-6.135667799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1df8cb3d9c2f%3A0xe2d694d1bb7de4e3!2sPT.%20Pelayaran%20Bahtera%20Adhiguna!5e0!3m2!1sen!2sid!4v1571679640200!5m2!1sen!2sid" height="225" frameborder="0" allowfullscreen=""></iframe>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-lg-6">
                                <div class="hubungi mt-5">
                                    <div class="hubungi-title"><h3>Branch office</h3></div>
                                    <h6 class="hubungi-pt">PT Pelayaran Bahtera Adhiguna</h6>
                                    <h6 class="branch-pt">Tanjung Jati</h6>
                                    
                                    <ul>

                                        <li class="list-one-r"> St. Raya Jepara Bangsri Km. 12, Sekuro Village RT 23 RW 05 Kec. Mlonggo, Kab. Jepara, 59452 - Indonesia. </li>

                                        <li class="list-two-r">Phone. 0291-7730660</li>

                                        <li class="list-four-r">Email: bagtjb2019@gmail.com</li>

                                    </ul>
                                </div>

                                <div class="hubungi mt-5">
                                    <h6 class="branch-pt">Pangkalan Susu</h6>
									<div class="caption mb-0">
										<span class="no-img">Site Office PLTU P. Susu Unit 3 & 4</span>
									</div>

                                    <ul>

                                        <li class="list-one-r"> Hamlet VI/Sei Dua, Tanjung Pasir Village, Kec. Pangkalan Susu, Kab. Langkat, North Sumatra - Indonesia</li>

                                        <li class="list-two-r">Phone. 0291-7730660</li>

                                        <li class="list-four-r">Email: bagtjb2019@gmail.com</li>

                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-6 border-left-grey">
                                <div class="hubungi left-space mt-5">
                                    <div class="caption hubungi-title mb-0">
										<h3>Affiliate</h3>
										<img src="../../assets/images/logo-adhiguna-putra.png" alt="icon" width="80" height="auto" class="align-self-center ml-space">
									</div>

                                    <h6 class="hubungi-pt">PT Adhi Guna Putera</h6>

                                    <ul>

                                        <li class="list-one-r">St. Kartini VII No.2 Central Jakarta 10750 - Indonesia</li>

                                        <li class="list-two-r">+62-21 22685989 / 22686001</li>

                                        <li class="list-three-r">+62-21 22685669</li>

                                        <li class="list-four-r">adhirapusat@yahoo.com</li>

                                        <li class="list-five-r">www.adhigunaputera.com</li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageContactUs',
    components: {
        Banner,
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
        }
    },
    mounted () {
        this.loadBanner()
    },
    methods: {
        loadBanner () {
            axios.get('hubungi_kami_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        }
    }
}
</script>

<style scoped>
    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 35px;
            height: auto;
        }
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 10px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .hubungi .caption {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 7px;
    }

    .hubungi .caption img {
        position: absolute;
    }

    .ml-space {
        margin-left: 250px;
    }

    .hubungi .caption span {
        font-family: "Montserrat", sans-serif;
        color: #404041;
        font-size: 15px;
        font-weight: 200;
        margin-left: 35px;
    }


    .hubungi .caption span.no-img {
        font-family: "Montserrat", sans-serif;
        color: #404041;
        font-size: 15px;
        font-weight: 200;
        margin-left: 0;
    }

    .map {
        position: relative;
        border: 4px solid #C7C9CB;
        width: 100%;
    }

    .border-bottom-grey {
        border-bottom: 2px solid #C7C9CB;
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .border-left-grey {
        border-left: 2px solid #C7C9CB;
    }

    .left-space {
        padding-left: 35px;
    }

    @media (max-width: 768px) {
        .border-left-grey {
            border-left: none;
        }
        .left-space {
            padding-left: 0;
        }
    }

    h3 {
        color: #0158a7;
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .hubungi h6.hubungi-pt {
        font-weight: 600;
        position: relative;
        font-size: 17px;
        color: #ea2e23;
    }

    ul li.list-one {
        list-style-image: url('../../assets/images/icon-map.svg');
    }

    ul li.list-two {
        list-style-image: url('../../assets/images/icon-phone.svg');
    }

    ul li.list-three {
        list-style-image: url('../../assets/images/icon-fax.svg');
    }

    ul li.list-four {
        list-style-image: url('../../assets/images/icon-mail.svg');
    }

    ul li.list-five {
        list-style-image: url('../../assets/images/icon-web.svg');
    }

    ul li.list-one-r {
        list-style-image: url('../../assets/images/icon-map-r.svg');
    }

    ul li.list-two-r {
        list-style-image: url('../../assets/images/icon-phone-r.svg');
    }

    ul li.list-three-r {
        list-style-image: url('../../assets/images/icon-fax-r.svg');
    }

    ul li.list-four-r {
        list-style-image: url('../../assets/images/icon-mail-r.svg');
    }

    ul li.list-five-r {
        list-style-image: url('../../assets/images/icon-web-r.svg');
    }

    @media screen and (max-width: 767px) {
        .text-span {
            font-size: 16px;
        }
    }

</style>